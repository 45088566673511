import React from "react";
import { useTheme, useToast } from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import { Field, Form as FinalForm } from "react-final-form";
import toastMessage from "../../../utilities/toastMessage";
import Box from "../../base/Box";
import Input from "../../base/Input";
import {
  composeValidations,
  email,
  phone,
  required,
} from "../../../validations";
import FormError from "../../base/FormError";
import Button from "../../base/Button";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Heading from "../../base/Heading";
import { post, put } from "../../../utilities/http";
import CustomCheck from "../../base/CustomCheck";
import Icon from "../../Icon";
import { STATUS } from "../../../utilities/constants";
import Switch from "../../base/Switch";

const AddUserForm = ({ location }) => {
  const theme = useTheme();
  const toast = useToast();
  const gap = 7;
  const user = get(location, "state.user", null);
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "60%",
    mb: gap,
    mx: 4,
    width: "60%",
  };

  const colFull = {
    minWidth: "100%",
  };

  const departmentOptions = [
    { label: "OP", value: "OP" },
    { label: "IT", value: "IT" },
    { label: "MGT", value: "MGT" },
  ];

  const permissionOptions = [
    { label: "ALL", value: "ALL" },
    { label: "FLEET", value: "FLEET" },
    { label: "ANALYTICS", value: "ANALYTICS" },
    { label: "TECHNICIAN", value: "TECHNICIAN" },
  ];

  return (
    <Route layout="admin">
      <Section>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: ["90%", null, null, "70%"],
            mb: 4,
            mx: "auto",
          }}
        >
          <Heading {...theme.variant.heading}>
            {user ? "Usuario de actualización" : "Añadir nuevo usuario"}
          </Heading>
        </Box>
        <Box
          sx={{
            bg: "white",
            borderRadius: 4,
            maxWidth: "90%",
            mx: "auto",
            px: 5,
            py: 10,
          }}
        >
          <FinalForm
            initialValues={{
              authorized: get(user, "authorized", 0),
              department: get(
                user,
                "department",
                get(departmentOptions, "[0].value")
              ),
              email: get(user, "mail"),
              lastName: get(user, "last_name"),
              name: get(user, "name"),
              permission: get(
                user,
                "permits",
                get(permissionOptions, "[0].value")
              ),
              phone: get(user, "phone"),
            }}
            onSubmit={async (values) => {
              try {
                const payload = user
                  ? {
                      ...user,
                      authorized: values.authorized
                        ? STATUS.ACTIVE
                        : STATUS.INACTIVE,
                      department: values.department,
                      last_name: values.lastName,
                      mail: values.email,
                      name: values.name,
                      permits: values.permission,
                      phone: values.phone,
                    }
                  : {
                      authorized: values.authorized
                        ? STATUS.ACTIVE
                        : STATUS.INACTIVE,
                      department: values.department,
                      last_name: values.lastName,
                      mail: values.email,
                      name: values.name,
                      permits: values.permission,
                      phone: values.phone,
                    };
                let data;
                let message;
                if (user) {
                  data = await put("/update-user", payload);
                  message = data && "User updated!";
                } else {
                  data = await post("/add-user", payload);
                  message = data && "User Added!";
                }
                if (data) {
                  toastMessage({
                    message:
                      get(data, "success") === STATUS.INACTIVE
                        ? get(data, "msm", "Internal server Error")
                        : message,
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                }
                navigate("/users");
              } catch (e) {
                toastMessage({
                  message: e.message,
                  toast,
                  type: "error",
                });
              }
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    as={Field}
                    component={Input}
                    name="email"
                    placeholder="Correo"
                    validate={composeValidations(required, email)}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="name"
                    placeholder="Nombre"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="lastName"
                    placeholder="Apellido"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="phone"
                    placeholder="Teléfono"
                    type="number"
                    validate={composeValidations(required, phone)}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "center",
                      mb: gap,
                      mx: 4,
                      width: "60%",
                    }}
                  >
                    <Box
                      as="label"
                      sx={{
                        alignSelf: "center",
                        color: "text.primary",
                        display: "block",
                        fontSize: 1,
                        fontWeight: "regular",
                        letterSpacing: 0,
                        mr: 3,
                        my: 2,
                        width: ["50%", null, "25%"],
                      }}
                    >
                      *Departamentos:
                    </Box>
                    {departmentOptions.length > 0 &&
                      departmentOptions.map((option) => (
                        <Box
                          sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            m: [3, null, 0],
                            width: ["50%", null, "25%"],
                          }}
                        >
                          <Box
                            key={option.value}
                            as={Field}
                            component={CustomCheck}
                            customActiveContent={
                              <Icon svg="radioActive" sx={{ width: "24px" }} />
                            }
                            customContent={
                              <Icon
                                svg="radioInactive"
                                sx={{ width: "24px" }}
                              />
                            }
                            htmlFor={option.value}
                            label={option.label}
                            labelSx={{
                              display: "flex",
                              flexWrap: "wrap",
                              fontSize: 1,
                              fontWeight: "regular",
                            }}
                            name="department"
                            onClick={() => {}}
                            type="radio"
                            validate={required}
                            value={option.value}
                          />
                        </Box>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "center",
                      mb: gap,
                      mx: 4,
                      width: "60%",
                    }}
                  >
                    <Box
                      as="label"
                      sx={{
                        alignSelf: "center",
                        color: "text.primary",
                        display: "block",
                        fontSize: 1,
                        fontWeight: "regular",
                        letterSpacing: 0,
                        my: 2,
                        width: ["50%", null, "25%"],
                      }}
                    >
                      *Permisos:
                    </Box>
                    {permissionOptions.length > 0 &&
                      permissionOptions.map((option) => (
                        <Box
                          sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            m: [3, null, 0],
                            width: ["50%", null, "25%"],
                          }}
                        >
                          <Box
                            key={option.value}
                            as={Field}
                            component={CustomCheck}
                            customActiveContent={
                              <Icon svg="radioActive" sx={{ width: "24px" }} />
                            }
                            customContent={
                              <Icon
                                svg="radioInactive"
                                sx={{ width: "24px" }}
                              />
                            }
                            htmlFor={option.value}
                            label={option.label}
                            labelSx={{
                              display: "flex",
                              flexWrap: "wrap",
                              fontSize: 1,
                              fontWeight: "regular",
                            }}
                            name="permission"
                            onClick={() => {}}
                            type="radio"
                            validate={required}
                            value={option.value}
                          />
                        </Box>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      alignItems: "flex-start",
                      display: "flex",
                      maxWidth: "60%",
                      width: "60%",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: 2,
                        maxWidth: "60%",
                        mr: 3,
                        my: 3,
                        textTransform: "none",
                      }}
                    >
                      ¿Autorizado?
                    </Box>
                    <Box
                      as={Field}
                      component={Switch}
                      label=""
                      name="authorized"
                      toggleSwitchSx={{ ml: 5, my: 4 }}
                      type="checkbox"
                    />
                  </Box>
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      my: 5,
                      ...colFull,
                    }}
                  >
                    <Button
                      disabled={formContext.pristine || formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ mr: 3, width: "130px" }}
                      type="submit"
                      variant="primary"
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/users");
                      }}
                      sx={{ ml: 3, width: "130px" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
      </Section>
    </Route>
  );
};

export default AddUserForm;
