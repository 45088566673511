import React, { useEffect, useState } from "react";
import {
  useToast,
  useTheme,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import moment from "moment";
import { Form as FinalForm } from "react-final-form";
import { useLocation } from "@reach/router";

import { Switch } from "@chakra-ui/switch";
import { get as GET, post, put } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Heading from "../../base/Heading";
import Button from "../../base/Button";
import Spinner from "../../base/Spinner";
import Table from "../../Table";
import Icon from "../../Icon";
import { STATUS, TOTAL_JUMP_PAGES } from "../../../utilities/constants";
import search from "../../../utilities/search";
import dashboardConstants from "../../../utilities/dashboard-constants";
import responsiveCss from "../../../utilities/responsiveCss";
import generateSearchString from "../../../utilities/generate-search-string";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";
import Modal from "../../base/Modal";

const UserListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const location = useLocation();
  const [updateUser, setUpdateUserData] = useState({});
  const [userData, setUserData] = useState({});
  const [sample, setSample] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  // const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const sampleToggle = () => {
    setSample(!sample);
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "users",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(`/list-user?${generateSearchString(query)}`);
      if (data) {
        const filteredUserData = searchValue
          ? search(get(data, "data", []), searchValue, "name")
          : get(data, "data", []);

        setUserData({ ...data, data: filteredUserData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location, sample]);
  const tableHeader = [
    { label: "#", value: "systemId" },
    { label: "Usuario", value: "user" },
    { label: "E-mail", value: "city" },
    { label: "Teléfono", value: "phone" },
    { label: "Departamentos", value: "department" },
    { label: "Permisos", value: "permission" },
    { label: "Autorizado", value: "authorized" },
    { label: "Creación", value: "creationDate" },
    { label: "Acciones", value: "actions" },
  ];
  const pageTitle = "Listado de usuarios";
  // const loading = false;
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  return (
    <Route isPrivate layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
          <Button
            onClick={() => {
              navigate("/users/add-users");
            }}
          >
            Añadir nuevo usuario
          </Button>
        </Box>
        <Box>
          <FinalForm
            onSubmit={async () => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, "row"],
                    mb: 8,
                  }}
                >
                  <Box
                    as="input"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      sampleToggle();
                    }}
                    placeholder="Search"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                </Box>
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(userData, "data", []).map((user) => [
                  get(user, "user_id", "-"),
                  `${get(user, "name", "-")} ${get(user, "last_name", "-")}`,
                  get(user, "mail", "-"),
                  get(user, "phone", "-"),
                  get(user, "department", "-"),
                  get(user, "permits", "-"),
                  <Switch
                    key={`status-${user.user_id}`}
                    colorScheme="green"
                    defaultChecked={get(user, "authorized") === STATUS.ACTIVE}
                    onChange={async () => {
                      try {
                        const payLoad = {
                          authorized:
                            get(user, "authorized") === STATUS.ACTIVE
                              ? STATUS.INACTIVE
                              : STATUS.ACTIVE,
                          department: get(user, "department"),
                          last_name: get(user, "last_name"),
                          mail: get(user, "mail"),
                          name: get(user, "name"),
                          permits: get(user, "permits"),
                          phone: get(user, "phone"),
                          user_id: get(user, "user_id"),
                        };
                        const data = await put("/update-user", payLoad);
                        sampleToggle();
                        if (data) {
                          toastMessage({
                            message: "User Edited!",
                            toast,
                            type: "success",
                          });
                        }
                      } catch (e) {
                        toastMessage({
                          message: e.message,
                          toast,
                          type: "error",
                        });
                      }
                    }}
                    variant="primary"
                  />,
                  get(user, "created_at")
                    ? moment(get(user, "created_at")).format("LLLL")
                    : "-",
                  <Menu>
                    <MenuButton
                      aria-label="Options"
                      as={IconButton}
                      icon={
                        <Icon svg="more-horizontal" sx={{ width: "20px" }} />
                      }
                      sx={{
                        "&:hover": {
                          bg: "transparent",
                        },
                        ":active": {
                          bg: "transparent",
                        },
                        bg: "transparent",
                      }}
                    />
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          navigate(`/users/${get(user, "user_id")}`, {
                            state: { user },
                          });
                        }}
                        sx={{
                          "&:hover": {
                            bg: "lightGreen",
                          },
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setUpdateUserData(get(user, "user_id"));
                          deleteToggle();
                        }}
                        sx={{
                          "&:hover": {
                            bg: "lightGreen",
                          },
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>,
                ])}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {userData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(userData, "data", []).length}
                  total={get(userData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
        <Modal
          bodySx={{ pb: 0 }}
          footer={
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                onClick={async () => {
                  const data = await post("/del-user", { user_id: updateUser });
                  toastMessage({
                    message:
                      get(data, "success") === STATUS.INACTIVE
                        ? get(data, "msm")
                        : "User deleted successfully!",
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                  deleteToggle();
                  sampleToggle();
                }}
                sx={{ mr: 3 }}
              >
                Yes
              </Button>{" "}
              <Button
                onClick={() => {
                  deleteToggle();
                }}
                sx={{ ml: 3 }}
              >
                Cancel
              </Button>
            </Box>
          }
          onClose={deleteToggle}
          open={deleteModal}
        >
          <Box
            sx={{
              color: "mediumGreen",
              display: "flex",
              justifyContent: "center",
              my: 5,
            }}
          >
            Are you sure to delete this user?
          </Box>
        </Modal>
      </Section>
    </Route>
  );
};
export default UserListingPage;
