import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@chakra-ui/react";
import Box from "../Box";
import InputWrapper from "../InputWrapper";

const CustomCheck = ({
  customActiveContent,
  customCheckValue,
  customContent,
  input,
  htmlFor,
  labelSx,
  setValidateError,
  sx,
  type,
  wrapperSx,
  ...rest
}) => {
  const theme = useTheme();
  const isCustom = customContent || customActiveContent;
  const wrapperStyled = {
    alignItems: "center",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    ...wrapperSx,
  };
  const inputCustomStyled = {
    "&:checked + div": {
      "> *:first-child": {
        display: "none",
      },
    },
    "&:not(:checked)+ div": {
      "> *:last-child": {
        display: "none",
      },
    },
    height: "100%",
    left: "0",
    opacity: "0",
    position: "absolute",
    width: "100%",
    zIndex: theme.zIndices[1],
    ...sx,
  };
  const labelStyled = {
    color: "colors.text",
    // flex: 'auto',
    fontSize: theme.fontSizes[1],
    fontWeight: theme.fontWeights.regular,
    letterSpacing: "normal",
    mb: theme.space[0],
    ml: theme.space[3],
    textTransform: "normal",
    ...labelSx,
  };
  return (
    <InputWrapper
      customCheckValue
      htmlFor={htmlFor || input.name}
      input={input}
      labelSx={labelStyled}
      setValidateError={setValidateError}
      wrapperSx={wrapperStyled}
      {...rest}
    >
      {({ ...inputRest }) => (
        <>
          <Box
            as="input"
            id={htmlFor || input.name}
            sx={isCustom ? inputCustomStyled : sx}
            type={type}
            {...inputRest}
            {...input}
          />
          {isCustom && (
            <Box sx={{ display: "flex" }}>
              {customContent}
              {customActiveContent}
            </Box>
          )}
        </>
      )}
    </InputWrapper>
  );
};

CustomCheck.propTypes = {
  customActiveContent: PropTypes.node,
  customCheckValue: PropTypes.bool,
  customContent: PropTypes.node,
  htmlFor: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelSx: PropTypes.shape({}),
  setValidateError: PropTypes.func,
  sx: PropTypes.shape({}),
  type: PropTypes.oneOf(["checkbox", "radio"]),
  wrapperSx: PropTypes.shape({}),
};

CustomCheck.defaultProps = {
  customActiveContent: null,
  customCheckValue: false,
  customContent: null,
  htmlFor: null,
  label: "",
  labelSx: {},
  setValidateError: () => null,
  sx: {},
  type: "checkbox",
  wrapperSx: {},
};

export default CustomCheck;
