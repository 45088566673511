import React from "react";
import { Router } from "@reach/router";

import UserListingPage from "../components/PageComponents/UserListingPage";
import UserAddPage from "../components/PageComponents/UserAddPage";
import UserUpdatePage from "../components/PageComponents/UserUpdatePage";

const Users = (props) => (
  <Router>
    <UserListingPage {...props} path="/users" />
    <UserAddPage {...props} path="/users/add-unit" />
    <UserUpdatePage {...props} path="/users/:userId" />
  </Router>
);

export default Users;
